/* @import url('https://fonts.googleapis.com/css2?family=Inter&family=JetBrains+Mono:wght@100&family=Lato&family=Nunito&family=Oswald&display=swap'); */
@font-face {
    font-family: 'JetBrains Mono';
    src: local('JetBrains Mono'), url(./../fonts/JetBrainsMono-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./../fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(./../fonts/Raleway-Regular.ttf) format('truetype');
}

body{
    font-family:'Raleway';
}

.container-libera {
    height: 100vh;
    background-image: url('../images/background_sac.png') !important;
    background-size: cover;
  }