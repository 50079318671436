body {
  margin: 0;
}

.container-login {
  height: 100vh;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-image: url("../../images/background_sac.png") !important;
  background-size: cover;
}

.button-login {
  background-color: #66cc66;
  border-color: inherit !important;
}

.button-login:hover,
.button-login:focus,
.button-login:active {
  background-color: #339966 !important;
  border-color: inherit !important;
  box-shadow: none;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: 10px;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
}
@media all and (min-width: 480px) {
  .login {
    padding: 10px 0;
    width: 350px;
  }
}
