.paginationItemStyle {
  .page-link {
    color: #383838;
    transition: 0.4s;
  }
  &.page-item {
    button {
      border-radius: 18px;
      margin-left: 0.4rem;
      margin-right: 0.4rem;
    }
    &.active {
      button {
        background-color: #66cc99;
        border-color: #66cc99;
        color: #fff;
        box-shadow: none;
      }
    }
  }
}

.paginationLinkStyle {
  background-color: #fff;
  color: #383838;
  transition: 0.4s;
  &:hover {
    background-color: #eeeeee;
    color: #474747;
  }
  &:active {
    background-color: #66cc99;
    color: #fff;
    box-shadow: none;
  }
  &:disabled {
    color: #797979;
    background-color: #d3d3d3;
    border-color: #d3d3d3;
  }
}
