.tabela-scroll {
    overflow: auto;    
}
.table thead tr{    
    line-height: 10px !important; 
    background-color: #ffffff;
    font-size: 14px;
    color: #504f4f;
}
.table tbody tr{
    line-height: 10px !important; 
}
.table tbody tr td span {
    line-height: 10px !important; 
}
.span-acoes{
    margin-top: -10px;
    margin-bottom: -10px; /*Faz a linha do grid ficar com uma altura boa devido os botões das ações */
    line-height: 10px !important;   
}
.dropdown-acoes{
    margin-top: -15px;
    margin-bottom: -10px; /*Faz a linha do grid ficar com uma altura boa devido os botões das ações */
    line-height: 10px !important;   
}
.btn-vertical{
    margin-top: 20px;
    max-height: 23px;
}
.icon-vertical{
    margin-top: -13px;
    max-height: 25px !important; 
}

.table-striped tbody tr:nth-child(odd) td, .table-striped tbody tr:nth-child(odd) th {
   background-color: #f4f7f9;
 }

 .table-striped tbody tr:nth-child(even) td, .table-striped tbody tr:nth-child(even) th {
    background-color: #ffffff;
  }

.table_td{
   line-height : 110%; 
   overflow-wrap : 'break-all';
}

.table_head{
    color: white;
    background-color: #2cc185;
}

.even_row{
    background-color: #f1f1f1;
}

.odd_row{
    background-color: white;
}