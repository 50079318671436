.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15vh;
    width: 35rem;
}

@media (max-width: 767px) {
    .logo {
        width: 15rem;
        margin-top: 25vh;
    }
  }