.container {
    margin-left: -.8rem;
}

.checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    display: block;
    width: 1.6em;
    height: 1.6em;
    margin-left: 1rem;
    border-radius: 0.15em;
    background-color: #fff;
    border: 0.10em solid #cccccc;
    outline: none;
    cursor: pointer;
  }

  .checkbox-wrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0 !important;
    background-color: #c0c0c0 !important;
  }
  
  .checkbox-wrapper input[type="checkbox"]:disabled + span {
    color: #c0c0c0;
  }
  
  .checkbox-wrapper input[type="checkbox"]:focus {
    box-shadow: 0 0 5px #2bacf1;
  }

  input.checked {
    background-color: #1786c0 !important;
    border: 0.10em solid #1786c0 !important;
    position: relative;
  }

  input.checked::before {
    content: "\2714";
    font-size: 1.5em;
    color: #fff !important;
    position: absolute;
    right: 1px;
    top: -5px;
  }