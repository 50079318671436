.carregamento{
    position: absolute;
    left: 1%;
    top: 1%;
    right: 1%;
    bottom: 1%;
    background-color: rgba(0,0,0,0.6);
    border-radius: 10px;
    animation: 0.4s ease-in;
}
.conteudo {
    padding: 5rem;                        
    align-items: center;
    animation: 0.4s ease-in;
}
.subconteudo {
    margin: 5px auto;
    display:flex;
    justify-content: center;
    animation: 0.4s ease-in;
  }