.pagination {
  display: flex;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.8rem;
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  color: #4b4b4b;
  border-radius: 25px;
  /* text-decoration: if($link-decoration == none, null, none); */
  background-color: #f2f2f2;
  border: #eee;
  box-shadow: none;
  transition-duration: 0.2s;
}

.page-link:hover {
  z-index: 2;
  color: #4b4b4b;
  text-decoration: none;
  background-color: #eee;
  border-color: #eee;
  box-shadow: none;
}

.page-link.active {
  z-index: 3;
  color: #4b4b4b;
  background-color: #e6eef6;
  border-color: #e6eef6;
  box-shadow: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #4b4b4b;
  background-color: #e6eef6;
  border-color: #e6eef6;
  box-shadow: none;
}

.page-item.disabled .page-link {
  position: relative;
  display: block;
  color: #cacaca;
  border-radius: 25px;
  background-color: #f2f2f2 !important;
  border: #eee;
  box-shadow: none;
}
