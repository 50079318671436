body {
  background-color: #f2f2f2 !important;
  background-image: none !important;
  /*background-image: url('../../images/background_sac.png') !important;
    background-size: cover;*/
}

.logo-menu {
  height: 57px;
  background-color: #2cc185;
  padding: 2px;
  text-align: center;
}

.menu-header {
  color: #fff !important;
}

.menu-esquerdo ul li {
  padding: 0px 5px;
}

.conteiner-principal {
  width: 100%;
  height: 100vh;
}

.sidebar {
  text-align: center;
  background-color: #2cc185;
  min-width: 50px;
  max-width: 50px;
  height: 100% !important;
  transition: all 0.3s;
  position: fixed;
  z-index: 2;
}
.sidebar ul li a {
  display: block;
  padding: 5px 5px;
  color: #ffffff;
  text-decoration: none;
}
.sidebar ul li a:hover {
  color: #339966;
  background-color: #ffffff;
  border-radius: 5px;
}
.sidebar ul li a:focus {
  color: #339966 !important;
  background-color: #ffffff;
  border-radius: 5px;
}
.sidebar ul ul a {
  padding-left: 0.5rem;
  background-color: #ffffff;
}
/*Seletor utilizado para ativar o botão ocultar menu em PC*/
.sidebar.toggled {
  margin-left: -50px;
}

.content {
  margin: 60px 5px 0px 55px;
  border-radius: 7px;
  width: calc(100% - 50px);
}
.content-toggled {
  margin: 60px 5px 0px 5px;
  border-radius: 7px;
  width: 100%;
}

@media (max-width: 768px) {
  .sidebar {
    margin-left: -50px;
  }
  .sidebar.toggled {
    margin-left: 0px;
  }
  .content {
    margin: 60px 5px 0px 5px;
    border-radius: 7px;
    width: 100%;
  }
}
.sidebar [data-toggle="collapse"] {
  position: relative;
}
.sidebar [data-toggle="collapse"]:before {
  content: "\f0d7";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  right: 1rem;
}
/* .sidebar [aria-expanded="true"] {
  /* background-color: #26be4c; */

.sidebar [aria-expanded="true"]:before {
  content: "\f0d8";
}
.titulo {
  font-size: 2rem !important;
}

.logo-barra-de-ferramentas {
  flex-direction: row !important;
}

.btn-acoes {
  max-height: 28px;
}
.icon-acoes {
  margin-top: -5px;
  max-height: 24px !important;
}

.btn-ativa {
  background-color: #28a745;
  border-color: #28a745 !important;
  color: #fff;
  transition: 0.4s;
}
.btn-ativa:hover,
.btn-ativa:active,
.btn-ativa:focus {
  background-color: #fff !important;
  color: #28a745 !important;
  box-shadow: none;
}

.btn-desativa {
  background-color: #ffc107;
  border-color: #ffc107 !important;
  color: #383838;
  transition: 0.4s;
}
.btn-desativa:hover,
.btn-desativa:active,
.btn-desativa:focus {
  background-color: #fff !important;
  color: #ffc107 !important;
  box-shadow: none;
}

.btn-exclui {
  background-color: #cc5662;
  border-color: #cc5662 !important;
  color: #fff;
  transition: 0.4s;
}
.btn-exclui:hover,
.btn-exclui:active,
.btn-exclui:focus {
  background-color: #fff !important;
  color: #cc5662 !important;
  box-shadow: none;
}

.botao-secundario {
  background-color: #1786c0;
  color: #ffffff;
  border-color: #1786c0 !important;
  border-width: 1.95px;
  border-radius: 6px;
}
.botao-secundario:hover,
.botao-secundario:active,
.botao-secundario:focus {
  background-color: #fff !important;
  color: #1786c0 !important;
  box-shadow: none;
}

.botao-ok {
  background-color: #1786c0;
  color: #ffffff;
  border-color: #1786c0 !important;
  border-width: 1.95px;
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
}
.botao-ok:hover {
  background-color: #ffffff;
  color: #1786c0;
  box-shadow: none;
}
.botao-ok:active,
.botao-ok:focus {
  background-color: #ffffff !important;
  color: #1786c0 !important;
  box-shadow: none;
}

.botao-primario {
  background-color: #66cc66 !important;
  border-color: inherit !important;
  border-width: 1.95px;
  border-radius: 6px;
}

.botao-primario:hover,
.botao-primario:active {
  background-color: #fff !important;
  color: #66cc66 !important;
  border-color: #66cc66;
  box-shadow: none;
}

.botao-primario:focus {
  box-shadow: none;
}

.botao-primario-exclusao {
  background-color: #dc3545;
  color: #ffffff;
  border-color: #dc3545;
  border-width: 1.95px;
  border-radius: 6px;
}
.botao-primario-exclusao:hover,
.botao-primario-exclusao:active,
.botao-primario-exclusao:focus {
  background-color: #fff;
  border-color: #dc3545;
  color: #dc3545;
  box-shadow: none;
}

.textarea {
  height: 150px !important;
}